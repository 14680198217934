<template>
  <div class="max-w1200 mrauto flex">
    <div class="per-w80">
      <div class="ptb30 borb-E6E6E6">
        <div class="pd20 bg-F3F3F3">
          <div class="fwb fs-26 col-333333">
            {{ info.title }}
          </div>
          <div class="fs-14 flex a-center j-sb mt14">
            <div>
              <!-- <span class="mr20 ln-bl col-333333"
                >发布时间：{{ info.create_time_text }}</span
              > -->
              <span class="mr20 ln-bl col-333333">信息编号：{{ info.code }}</span>
            </div>
            <div class="flex a-center">
              <div
                class="col-CC0000 flex mr15 a-center cup"
                v-if="info.status == 4 && info.status != 3 && info.status != 10"
              >
                <div class="mr10 w18h18">
                  <img src="../../assets/img/jubao.png" alt="" class="w18h18" />
                </div>
                已举报
              </div>
              <div
                class="col-CC0000 flex mr15 a-center cup"
                @click="dialogShow(4)"
                v-if="info.status != 4 && info.status != 3 && info.status != 10"
              >
                <div class="mr10 w18h14">
                  <img
                    src="https://pt.baipubang.com/app_icon/index/jubao.png"
                    alt=""
                    class="w18h14"
                  />
                </div>
                举报已转
              </div>
              <!-- <div
            class="col-CC0000 flex a-center cup"
            @click="dialogShow(1)"
            v-if="info.is_coll == 1"
          >
            <div class="mr10 w18h18">
              <img
                src="https://pt.baipubang.com/app_icon/index/yidainzan.png"
                alt=""
                class="w18h18"
              />
            </div>
            已收藏
          </div>
          <div class="col-CC0000 flex a-center cup" @click="collectClick" v-else>
            <div class="mr10 w18h14">
              <img src="../../assets/img/shoucang.png" alt="" class="w18h14" />
            </div>
            感兴趣
          </div> -->
            </div>
          </div>
        </div>
        <div class="flex a-center ptb30 borb-E6E6E6">
          <div class="text-center per-w33">
            <div class="fs-30 fwb col-FF0000">
              {{ info.area_min }}~{{ info.area_max
              }}<span v-if="info.area_unit == 1">㎡</span>
              <span v-if="info.area_unit == 2">亩</span>
            </div>
            <div class="fs-14 col-333333 mt28">期望面积</div>
          </div>
          <div class="text-center per-w33 borl-E6E6E6">
            <div class="fs-30 fwb col-FF0000">
              <span v-if="info.rent_price_list == 0">面谈</span>
              <span v-else
                >{{ info.rent_min }}~{{ info.rent_max }}元/
                <span v-if="info.rent_type == 1">年</span>
                <span v-if="info.rent_type == 2">月</span>
                <span v-if="info.rent_type == 3">平方</span></span
              >
            </div>
            <div class="fs-14 col-333333 mt28">期望租金</div>
          </div>
          <div class="text-center per-w33 borl-E6E6E6">
            <div class="fs-30 fwb col-FF0000">
              <span>{{ info.classify_two[0].name }}</span>
            </div>
            <div class="fs-14 col-333333 mt28">求租行业</div>
          </div>
        </div>
        <div class="flex ptb30">
          <div class="per-w70 mr20 fs-14 flex a-center">
            <div class="mb10 per-w33">
              <span>期望租期：</span>
              <span>{{ info.expect_lease_term }}年</span>
            </div>
            <div class="mb10 per-w33">
              <span>期望区域：</span>
              <span v-for="(item, index) in info.expect_area" :key="index"
                >{{ item.name }},</span
              >
            </div>
          </div>
          <div
            class="per-w30"
            v-if="
              (info.status !== 3 && info.ad_loc == 1) ||
              (info.status !== 3 && info.ad_loc == 2)
            "
          >
            <img
              src="https://pt.baipubang.com/uploads/img/xiangqing/zhang.png"
              alt=""
              class="w146h146"
            />
          </div>
          <div
            class="per-w30"
            v-if="(info.status == 3 && s_type == 1) || (s_type == 2 && info.status == 3)"
          >
            <img
              src="https://pt.baipubang.com/uploads/img/xiangqing/yichengjiao.png"
              alt=""
              class="w146h146"
            />
          </div>
        </div>
        <div>
          <div class="flex">
            <div v-if="user_type > 0" class="per-w60 mr15">
              <div class="ptb14lr35 br10 bg-CC0000 col-ffffff flex a-center h70 box-b">
                <div class="per-w20 borr-ffffff text-center fs-20">
                  {{ info.contacts || info.name }}
                </div>
                <div class="per-w80 pl30 flex a-center j-sb">
                  <div class="mr20">
                    {{ info.phone }}
                  </div>
                  <!-- <div class="ptb4lr11 br100 fs-14 bor-ffffff cup">在线咨询</div> -->
                </div>
              </div>
            </div>
            <div class="per-w40 flex a-end">
              <div class="ptb14lr18 br10 bg-EEEEEE fs-14 col-333333 h70 box-b mr15">
                <div>
                  <span>客服电话：</span>
                  <span class="fwb col-CC0000">{{ info.salesman_phone }}</span>
                </div>
                <div class="mt5">想了解更多信息，请拨打电话！</div>
              </div>
              <div v-if="info.status != 3 && info.status != 10">
                <el-button type="danger" @click="dialogShow(2)">乐分享</el-button>
              </div>
            </div>
          </div>
          <!-- <div class="flex a-center j-sb mt23" v-if="info.status != 3">
            <div></div>
            <div v-if="info.status != 10">
              <el-button type="danger" @click="dialogShow(2)">乐分享</el-button>
            </div>
          </div> -->
        </div>
      </div>
      <div class="borb-E6E6E6 mt50 flex a-center j-sb">
        <div class="flex a-center">
          <div class="h60 w140 flex a-center fs-24 borb-d43030 mr26">详细需求</div>
          <div
            class="h60 w140 cup flex a-center j-center fs-15 mr5"
            :class="item.id == type ? 'bg-d43030 col-ffffff' : 'bg-EEEEEE col-333333'"
            v-for="(item, index) in typeList"
            :key="index"
            @click="WinportClick(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="ptb20 flex">
        <div class="ptb20 per-w80 mr20">
          <div class="" v-if="type == 1">
            <!-- <div class="col-CC0000 fs-20 mb30 ptb20">配套设施：</div> -->
            <div>
              <ul class="flex a-center">
                <li
                  class="w106h86 bor-c1c1c1 flex j-center a-center"
                  v-for="(item, index) in info.matching"
                  :key="index"
                >
                  <div>
                    <div class="w42h39 mrauto mb10">
                      <img :src="item.img" alt="" class="w42h39" />
                    </div>
                    <div class="text-center fs-16 col-333333">
                      {{ item.name }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 相似推荐 -->
    <div class="per-w20 mt30 ml15 bor-DADADA">
      <div class="h40 l-h40 bg-CC0000 fs-20 col-ffffff text-center">其他求租信息</div>
      <ul class="plr5">
        <li
          class="ptb15 borb-DADADA cup"
          v-for="(item, index) in recommendList"
          :key="index"
          @click="detailsCut(item)"
        >
          <div class="fs-12 col-333333">
            <div class="fs-14 ft-1 mb5 w120">求租：{{ item.title }}</div>
            <div class="mb5">
              求租区域：<span class="col-FF0000">{{ item.expect_area }}</span>
            </div>
            <div class="mb5">
              求租面积：<span class="col-FF0000"
                >{{ item.area_min }}~{{ item.area_max }}</span
              ><span class="col-FF0000" v-if="item.area_unit == 1">m²</span
              ><span class="col-FF0000" v-if="item.area_unit == 2">亩</span>
            </div>
            <div class="mb5">
              期望租金：<span class="col-FF0000" v-if="item.rent_price_list == 0"
                >面议</span
              ><span class="col-FF0000" v-else
                >{{ item.rent_min }}~{{ item.rent_max }}元/<span
                  v-if="item.rent_type == 1"
                  >年</span
                ><span v-if="item.rent_type == 2">月</span
                ><span v-if="item.rent_type == 3">平方</span></span
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
    <el-dialog title="温馨提示" :visible.sync="dialogVisible" width="30%">
      <span>是否要取消收藏</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delecollectClick">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="温 馨 提 示" :visible.sync="dialogVisible4" width="30%">
      <span>是否要举报此店铺</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible4 = false">取 消</el-button>
        <el-button type="primary" @click="jubao">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="乐分享" :visible.sync="dialogVisible2" width="30%">
      <span>{{ rich_val.text_e }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="copyFn(info.share_url)">复 制</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  find_shop_val,
  similar_find,
  add_collect,
  del_collect,
  report_shop,
} from "../../utils/api.js";
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible4: false,
      rich_val: "",
      typeList: [{ id: 1, name: "配套要求" }],
      type: 1,
      bannerImg: "",
      s_type: "",
      id: "",
      lng: "",
      lat: "",
      province: "",
      city: "",
      info: "",
      recommendList: "",
      pid: "",
      uid: "",
      user_type: "",
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        this.s_type = this.$route.query.s_type;
        this.id = this.$route.query.id;
        this.pid = this.$route.query.pid;

        console.log(this.id);
        this.getdata();
      },
    },
  },
  mounted() {
    this.s_type = this.$route.query.s_type;
    this.id = this.$route.query.id;
    this.pid = this.$route.query.pid;
    this.lng = window.localStorage.getItem("lng");
    this.lat = window.localStorage.getItem("lat");
    this.province = window.localStorage.getItem("province");
    this.city = window.localStorage.getItem("city");
    let user_type = window.localStorage.getItem("user_type");
    if (user_type) {
      this.user_type = user_type;
    }
    let uid = window.localStorage.getItem("uid");
    if (uid) {
      this.uid = uid;
    } 
    // else {
    //   this.$message.error("还未登录，请登录后再使用");
    //   setTimeout(() => {
    //     this.$router.push({
    //       path: "/login",
    //       query: { navid: 10 },
    //     });
    //     // let routeData = this.$router.resolve({
    //     //   path: "/login",
    //     //   query: { navid: 10 },
    //     // });
    //     // window.open(routeData.href, "_blank");
    //   }, 1500);
    // }
    this.getdata();
    this.getrecommend();
  },
  methods: {
    dialogShow(i){
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return ;
      }

      switch (i){
        case 1:
          this.dialogVisible = true;
          break;
        case 2:
          this.dialogVisible2 = true;
          break;
        case 4:
          this.dialogVisible4 = true;
          break;
        default:
          break;
      }
    },
    jubao() {
      let that = this;
      report_shop({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          that.dialogVisible4 = false;
          that.$alert(res.msg, {
            callback: () => {
              that.getdata();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          that.dialogVisible4 = false;
          that.$alert(err.msg, {
            callback: () => {
              that.getdata();
            },
          });
        });
    },
    copyFn(val) {
      console.log(val);
      // createElement() 方法通过指定名称创建一个元素
      var copyInput = document.createElement("input");
      //val是要复制的内容
      copyInput.setAttribute("value", val);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyInput);
          this.$message.success("已复制链接,快去分享吧");
          this.dialogVisible2 = false;
        }
      } catch {
        this.$message.error("复制失败，请检查浏览器兼容");
      }
    },
    // 取消收藏
    delecollectClick() {
      let that = this;
      that.dialogVisible = false;
      del_collect({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          this.$alert(res.msg, {
            callback: () => {
              that.getdata();
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$alert(err.msg, {
            callback: () => {
              that.getdata();
            },
          });
        });
    },
    // 收藏
    collectClick() {
      let uid = window.localStorage.getItem("uid");
      if (uid) {
        this.uid = uid;
      } else {
        this.$message.error("还未登录，请登录后再使用");
        setTimeout(() => {
          this.$router.push({
            path: "/login",
            query: { navid: 10 },
          });
          // let routeData = this.$router.resolve({
          //   path: "/login",
          //   query: { navid: 10 },
          // });
          // window.open(routeData.href, "_blank");
        }, 1500);
        return ;
      }

      let that = this;
      add_collect({
        uid: that.uid,
        id: that.id,
        type: that.s_type,
      })
        .then((res) => {
          console.log(res);
          this.$alert(res.msg, {
            callback: () => {
              that.getdata();
            },
          });
        })
        .catch((err) => {
          this.$alert(err.msg, {
            callback: () => {
              that.getdata();
            },
          });
        });
    },
    // 跳转详情
    detailsCut(item) {
      console.log(item);
      this.$router.push({
        path: "/Finddetails",
        query: { navid: 10, s_type: item.s_type, id: item.id },
      });
      // let routeData = this.$router.resolve({
      //   path: "/Finddetails",
      //   query: { navid: 10, s_type: item.s_type, id: item.id },
      // });
      // window.open(routeData.href, "_blank");
    },
    WinportClick(id) {
      this.type = id;
    },
    getdata() {
      var that = this;
      find_shop_val({
        uid: that.uid,
        id: that.id,
        pid: that.pid,
      })
        .then((res) => {
          console.log(res);
          let arr = [];
          if (res.data.val.cover) {
            arr.push(res.data.val.cover);
          }
          if (res.data.val.more_img && res.data.val.more_img.length) {
            res.data.val.more_img.forEach((item) => {
              arr.push(item);
            });
          }
          that.bannerImg = arr;
          that.info = res.data.val;
          that.rich_val = res.data.rich_val;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getrecommend() {
      let that = this;
      similar_find({
        city: that.city,
        lat: that.lat,
        lng: that.lng,
        fit_industry: that.info.fit_industry_id,
      })
        .then((res) => {
          console.log(res);
          this.recommendList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-button{
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  height: 4.375rem;
}
</style>
